.its-hierarchy-list {
    overflow: auto;
    min-height: 98%;
    padding-bottom: 10px;
    @extend .margin-top;

    .its-hierarchy-list--column {
        min-width: 300px;
        max-width: 300px;
        background-color: #fafafa;
        padding: 0;
        border: 1px solid #eee;
        margin-left: 10px;

        .its-hierarchy-list--column-list-item {
            border-left: 5px solid #fff;
        }

        .its-hierarchy-list--column-list-item--active {
            border-left: 5px solid rgb(29, 129, 196);
        }

        .its-hierarchy-list--column-header {
            padding: 10px;
            border-bottom: 1px solid #eee;
        }

        .its-hierarchy-list--column-header .its-hierarchy-list--column-header-btn {
            margin-top: 3px;
        }

        .its-hierarchy-list--column-list-item div.md-no-style.md-button {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    .its-hierarchy-list--node {
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
        width: 285px;

        .its-hierarchy-list--node-remove-btn {
            margin-top: -5px;
        }

        .its-editable-text {
            .its-editable-text--label {
                margin-top: -3px;
            }

            .its-editable-text--input-container .its-editable-text--button-container {
                margin-top: -2px;
            }
        }
    }
}

.its-hierarchy--location-overlay-container {
    position: relative;
}

.its-hierarchy--location-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(216, 216, 216, 0.6);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}
