.its-login--splash {
    background: url('/assets/images/branding/intouch-login-splash.jpg') center;
    position: relative;
    background-size: cover;

    .its-login--splash-mask {
        background: linear-gradient(rgba(0, 0, 0, 0.36), rgba(0, 0, 0, 0.87));
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .its-login--splash-content {
        background-color: rgba(0, 0, 0, 0);
    }
}

.its-login-form {
    background-color: $White;
    min-width: 450px;
    max-width: 450px;
}

.its-login-container {
    padding: 64px;
    margin-top: auto;
}

.its-login-error-msg {
    @extend .its-text--small;
    @extend .its-margin-topbot__half;

    & a {
        text-decoration: underline;
        color: $DangerColour;
    }
}

@supports (-ms-ime-align: auto) {
    .its-login--logo {
        color: $PrimaryColour;
    }
}

.its-login--logo {
    margin-bottom: 42px;
    width: 250px;
    height: 100px;
    cursor: pointer;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        color: $PrimaryColour;
        margin: 0 auto;
    }
}

.its-login--register-logo {
    margin: 32px;
    width: 200px;
    height: 200px;

    svg {
        fill: $lightPrimaryColour;
    }
}

.its-login--forgot-pass {
    @extend .its-text--caption;
    @extend .its-text__light;
}

.its-login--data-capture-title {
    @extend .its-text--caption;
    @extend .its-text__white;
    @extend .its-text__strong;
}

.its-login--data-capture-cta {
    @extend .its-text--headline;
    @extend .its-text__white;
}

.its-login--signup-text {
    @extend .its-text__white;
}

.its-login--check-legacy-redirect {
    padding-top: 20px;
}
