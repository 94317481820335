.its-subscription-gradient {
    background: rgb(3, 176, 76);
    background: linear-gradient(45deg, rgba(3, 176, 76, 1) 0%, rgba(157, 214, 6, 1) 100%);
}

.its-subscription-card-container {
    min-width: 375px;
    max-width: 375px;

    @media (max-width: 1600px) {
        min-width: 22vw;
        max-width: 22vw;
    }

    @media (max-width: 1280px) {
        width: 80%;
        max-width: 400px;
    }

    margin: 10px;

    .its-subscription-your-plan {
        min-width: 100%;
        max-width: 100%;

        height: 40px;
        background: transparent;

        p {
            color: white;
            font-weight: 500;
            font-size: 16px;
        }

        &.its-subscription-your-plan--show {
            background: #ea4dbf;
        }
    }

    .its-subscription-card {
        padding: 20px;

        .its-subscription-card--costing {
            margin-top: 20px;
            height: 50px;

            .its-subscription-card--costing--price {
                font-size: 36px;
                line-height: 40px;
                font-weight: 500;
            }

            .its-subscription-card--costing--term {
                @extend .its-text--small;
            }
        }

        .its-subscription-card--cta {
            height: 60px;
            margin-top: 20px;

            button {
                height: 50px;
                border-radius: 4px;

                &:not([disabled]) {
                    @extend .its-subscription-gradient;
                }
            }
        }

        .its-subscription-card--limits {
            height: 120px;
            margin-top: 20px;

            p {
                @extend .its-text--small;
            }
        }

        .its-subscription-card--features {
            height: 300px;
            margin: 20px 20px 10px 30px;

            ul {
                list-style-type: disc;
                list-style-position: inside;
                text-indent: -22px;
                margin-left: 22px;

                li {
                    @extend .its-text--small;
                    position: relative;
                    margin-top: 8px;

                    &::marker {
                        font-size: 16px;
                    }
                }
            }

            .its-subscription-card--features--header {
                @extend .its-margin-bot;
            }
        }
    }
}
