.its-advanced-feature--page {
    overflow: hidden;
    background: linear-gradient(116.24deg, #3b82f6 0%, #06b6d4 100%);

    .its-advanced-feature--card {
        margin: 12px;
        padding: 16px;
        width: 375px;
        max-width: 375px;
        background-color: rgba(white, 0.05);
        border-radius: 16px;
        transition: background-color ease-out 200ms;
        cursor: pointer;

        &:hover {
            background-color: rgba(white, 0.1);
        }

        .its-feature-card--header {
            margin-bottom: 8px;

            h4 {
                color: white;
            }

            .its-feature-card--icon {
                color: white;
                background-color: rgba(white, 0.1);
                padding: 12px;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                font-size: 16px;
            }

            .purchased-banner {
                color: white;
                background-color: rgba(white, 0.1);
                padding: 2px 8px;
                border-radius: 4px;
                margin-left: auto;
            }
        }

        .its-advanced-feature--feature-list {
            margin-top: 12px;
            margin-left: 14px;
            color: white;

            i {
                margin-top: 4px;
            }
        }

        &.selected {
            background-color: white;

            h4 {
                color: $color-grey-800;
            }

            .its-feature-card--icon,
            .purchased-banner {
                background-color: $color-primary-50;
                color: $color-primary-500;
            }

            .its-advanced-feature--feature-list {
                color: $color-grey-800;
            }
        }
    }

    .its-advanced-feature--divider {
        margin: 24px 0;
        height: 100%;
        width: 1px;
        background-color: rgba(white, 0.1);
    }

    .its-advanced-feature--info-page {
        margin: 12px auto;
        padding: 12px 32px;
        border-radius: 16px;
        background-color: rgba(white, 0.05);
        color: white;
        max-width: 900px;

        h1 {
            font-size: 48px;
            line-height: 60px;
        }

        p {
            font-size: 16px;
            line-height: 24px;
        }

        .its-advanced-feature--info-bullet {
            background-color: white;
            box-shadow: 0px 4px 6px rgba(252, 91, 255, 0.25), 0px 10px 15px rgba(136, 35, 216, 0.15);
            border-radius: 8px;
            color: $color-grey-800;
            padding: 8px 24px;
            margin: 12px 0;
            font-size: 18px;
            line-height: 24px;
            max-width: 680px;
            width: 100%;
            align-self: center;

            i {
                margin: 4px 12px 0 0;
            }
        }

        .its-advanced-feature--image-container {
            margin: 32px 0;
            max-width: 600px;
            width: 100%;
            align-self: center;

            img {
                width: 100%;
            }
        }
    }

    .its-btn--text {
        color: white;
    }

    .its-btn--gradient {
        background: linear-gradient(
            90.36deg,
            #6366f1 0.31%,
            #a855f7 50.03%,
            #ec4899 99.73%,
            rgba(250, 250, 250, 0) 99.74%
        );
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgba(black, 0.3);
    }

    @media screen and (max-width: 959px) {
        .its-advanced-feature--info-page {
            background-color: transparent;
            padding: 12px;
            margin: 0;
            border-radius: 0;
            height: 100%;

            h1 {
                font-size: 28px;
                line-height: 42px;
            }
        }

        .its-advanced-feature--card {
            width: auto;
            padding: 8px;

            .its-feature-card--header {
                margin-bottom: 0;
            }
        }
    }

    @media screen and (max-width: 600px) {
        .its-advanced-feature--card {
            width: auto;
            margin: 6px;
            border-radius: 8px;

            .its-feature-card--header {
                margin-bottom: 0;
            }
        }
    }
}
