.its-stripe-form {
    .card-element-container {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .card-errors {
        color: red;
        margin-top: 3px;
        margin-left: 5px;
    }

    .StripeElement {
        box-sizing: border-box;

        height: 40px;
        padding: 10px 12px;

        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: white;

        box-shadow: 0 1px 3px 0 $LightGrey;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
    }

    .StripeElement--focus {
        box-shadow: 0 1px 3px 0 #cfd7df;
    }

    .StripeElement--invalid {
        border-color: #fa755a;
    }

    .StripeElement--webkit-autofill {
        background-color: #fefde5 !important;
    }

    .stripe-select {
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #ddd;
        border-radius: 4px;
        height: 40px;
        padding: 0 8px;
        margin: 0 0 8px;
        &.md-input-has-value {
            label {
                display: none;
            }
        }
        md-select {
            md-select-value {
                border: none;
            }
        }
    }
}
