.its-menu {
    background: white;
    border-radius: 4px;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14),
        0 5px 24px 4px rgba(0, 0, 0, 0.12);
    min-width: 250px;
}

.its-menu-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 48px;
    padding: 0;
    position: relative;
    transition: background 0.15s linear;
    width: auto;
}

md-progress-linear,
md-progress-linear .md-container,
md-progress-linear .md-container .md-bar {
    height: 2px;
}

.its-menu-item.selected {
    color: rgb(16, 108, 200);
}
