.its-billing--content {
    margin: 20px;
    padding: 20px;
    background: white;
    border-radius: 3px;
}

.its-billing--taxes-subtotal {
    font-size: 80%;
}
