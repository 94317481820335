.its-content--container {
    display: flex;
    justify-content: center;
}

.its-page-content-container {
    background: transparent;
}

.its-vertical-divider {
    width: 1px;
    border-right: 1px solid #ddd;
    padding: 0 2px;
    margin: 0 2px;
    height: 65%;
}
md-toolbar {
    &.its-toolbar--access {
        box-shadow: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        min-height: 50px;
        box-sizing: border-box;
        background: white;
    }
}

.its-content {
    background: rgba(255, 255, 255, 1);
    max-width: 936px;
    width: 100%;
    margin: 32px;
}

.bg-white {
    background: white;
}

md-toolbar {
    &.its-fake-tabs {
        background: white;
        max-height: 48px;
        min-height: 48px;

        md-tabs {
            max-height: 48px;
            min-height: 48px;

            md-tabs-wrapper {
                background-color: white;
                max-height: 48px;
                min-height: 48px;
            }
        }

        md-tab-item {
            border-bottom: none !important;

            &.md-active {
                border-bottom: 2px solid !important;
            }
        }
    }
}

.md-input-invalid {
    .its-select-element {
        border-color: $DangerColour;
        background-color: $InputDangerBG;

        .its-icon {
            color: $DangerColour;
        }
    }
}
