.its-modal {
    &.its-modal--padding__double {
        padding: 24px;
    }

    md-tabs {
        md-content {
            background: white;
        }
    }

    md-list {
        border: 1px solid rgba(0, 0, 0, 0.12);
        max-height: 320px;
        overflow: auto;

        .md-2-line {
            align-items: center;
        }
    }

    its-product-checkbox-list {
        padding: 16px 16px;
        border: 1px solid rgba(0, 0, 0, 0.12);
        display: block;

        md-checkbox:last-of-type {
            margin-bottom: 0;
        }
    }

    &.its-modal--group-edit {
        width: 500px;

        .its-modal--close-button {
            .its-modal--close-button-icon {
                color: #000;
            }
        }
    }
}

.its-modal--input-text {
    opacity: 0.7;
}

.its-modal--searchable-select-container {
    padding: 0 16px;
    background: rgb(250, 250, 250);
}

.its-modal--tab-loading-spinner {
    margin-bottom: 25px;
    margin-top: 15px;
}

.its-modal--object-search--tab-search {
    width: 99%;

    .its-modal--object-search--tab-autocomplete {
        background: rgb(255, 255, 255);
    }

    &.its-modal--object-search--tab-search-results {
        margin-bottom: 5px;
        background: rgb(255, 255, 255);

        .its-modal--object-search--tab-list {
            padding: 0;

            .its-modal--object-search--tab-list-item {
                background: rgb(250, 250, 250);

                .its-modal--object-search--tab-list-item-product-image {
                }

                .its-modal--object-search--tab-list-item-product-name,
                .its-modal--object-search--tab-list-item-user-name {
                    padding: 0 20px;
                    color: #000;
                }

                .its-modal--object-search--tab-list-item-user-email {
                    color: #555;
                }
            }

            .its-modal--object-search--tab-close-button {
                position: absolute;
                right: 0;
                top: 3px;
            }
        }
    }
}
