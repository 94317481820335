.its-activation-box {
    width: 100%;
    max-width: 600px;
}

.its-activation-box--logo {
    margin: 20px 0;
    height: 75px;
    text-align: center;

    md-icon {
        width: 75px;
        height: 75px;
    }
}

.its-activation-box--header {
    margin-bottom: 20px;
}
