.its-button--org-select {
    padding: 0 15px;
    text-align: left;
    min-width: initial;
}

.its-checkbox--white div.md-icon:after {
    border-color: rgba(255, 255, 255, 255.87);
}

.its-button--height-centered.md-button.md-icon-button {
    margin-top: auto;
    margin-bottom: auto;
}
