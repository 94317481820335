$baseTextColour: $fontColourBlack54;

$basePrimaryColour: #00a3e0;
$basePrimaryColour85: rgba(0, 163, 244, 085);
$lightPrimaryColour: #8cc7ee;
$PrimaryColour: $AccessColour_Primary;
$PrimaryColour_White: $AccessColour_White;
$PrimaryColour_Light: $AccessColour_Light;
$PrimaryColour_Dark: $AccessColour_Dark;
$backgroundLightGrey: #f6f6f6;
$backgroundDarkGrey: darken($backgroundLightGrey, 20%);
$brandLightGray: #58595b;

/*
 * Component color palette
 */
$textRed: #a94442;
$textYellow: #8a6d3b;
$textBlue: #31708f;
$textGreen: $PositiveColour;
$textError: $DangerColour;
$textWarning: $WarnColour;
