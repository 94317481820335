.its-users-edit--indented-checkbox {
    @extend .margin-top;
    margin-left: 25px;
}

.its-users--button-circular-progress {
    margin: 0 auto;

    svg path {
        stroke: white;
    }
}
