.its-organization--warning-modal-confirm-button {
    color: red;
}

.its-clear-image {
    position: absolute;
    top: 0;
    right: 0;
    background: white;
    cursor: pointer;
}

.its-organization--logo-uploading {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100px;
    width: 100px;
    background: #f8f8f8;
    border-radius: 3px;
    position: relative;
    border: 3px dashed #f0f0f0;

    md-progress-circular {
        margin-bottom: 20px;
    }
}

.its-organization--logo-error {
    align-items: start;
    color: $textError;
    padding: 10px;
    background: #f8f8f8;
    border: 1px solid #f0f0f0;
    border-left: 2px solid #f44336;

    @media screen and (max-width: 600px) {
        align-self: center;
        margin-top: 5px;
    }
}

.its-organization--logo {
    position: relative;
    margin-right: 20px;

    @media (max-width: 600px) {
        margin-right: auto;
        margin-left: auto;
    }
}
