.its-product-card {
    height: 250px;
    width: 400px;
    margin: 15px;
    padding: 20px 30px 0;
    border-radius: 5px;
    background: white;

    @include media-breakpoint-down('xs') {
        width: 100%;
        margin: 5px 0;
        border-radius: 0;
    }
}

.its-product-card--welcome {
    @extend .its-text--title;
    margin: 20px auto 15px 20px;
}

.its-product-card--caption {
    @extend .its-text__muted;
}

.its-product-card--logo-container {
    margin: 0 0 20px 0;
}

.its-product-card--title {
    @extend .its-text--title;
    margin: 0 0 0 15px;
    align-self: center;
    text-transform: capitalize;
}

.its-product-card--unowned {
    background: #ededed;
    img {
        opacity: 0.2;
    }
    color: #b5b5b5;
}
