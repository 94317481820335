.its-forgot--background {
    background-image: url('/assets/images/branding/intouch-login-splash.jpg');
    position: relative;
    background-size: cover;
    background-position: center;
}

.its-forgot--background-mask {
    background: linear-gradient(rgba(0, 0, 0, 0.36), rgba(0, 0, 0, 0.87));
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.its-forgot--container {
    margin-bottom: 200px;
}

.its-forgot--logo-container {
    max-height: 260px;
    transition: all ease 150ms;
}

.its-forgot--logo {
    height: 75px;
    width: auto;
    color: white;
}

.its-forgot--input-container {
    margin-bottom: 0;
    width: 450px;

    &.its-forgot--input-container__modal {
        width: 375px;
    }

    @include media-breakpoint-down('xs') {
        width: 225px;
    }
}

.its-forgot--password-match-error {
    color: rgb(221, 44, 0);
    font-size: 12px;
    line-height: 14px;
    padding-top: 5px;
}

.its-forgot--password-input-container {
    margin-left: 20px;
    @include media-breakpoint-down('xs') {
        margin-left: 0;
    }
}

.its-forgot--password-req-container {
    margin-left: 50px;
    @include media-breakpoint-down('xs') {
        margin-left: 0;
        margin-top: 20px;
    }
}

.its-forgot--password-requirements {
    color: rgba(0, 0, 0, 0.42);
    margin: 10px 0;
    line-height: 18px;
}

.its-forgot--password-required-character {
    color: rgba(0, 0, 0, 0.42);
    line-height: 18px;
    font-size: 14px;
}

.its-forgot--password-required-pass {
    color: rgb(0, 163, 224);
}

.its-forgot--password-required-fail {
    color: rgb(221, 44, 0);
}

.its-forgot--password-req-icon-pass {
    margin: 1px 5px 0 0;
    font-size: 16px;
    line-height: 16px;
    height: 16px;
    width: 16px;
    min-height: 16px;
    min-width: 16px;
    color: rgb(0, 163, 224);
}

.its-forgot--password-req-icon-fail {
    margin: 1px 5px 0 0;
    font-size: 16px;
    line-height: 16px;
    height: 16px;
    width: 16px;
    min-height: 16px;
    min-width: 16px;
    color: rgb(221, 44, 0);
}

.its-forgot--contact-button {
    height: 45px;
    display: flex;
    margin-top: 15px;
}

.its-forgot--new-customer-container {
    position: absolute;
    width: 100%;
    bottom: 0;
    background: transparent;
    margin-bottom: 30px;

    & p {
        color: white;
    }
}

@media (max-width: 600px) {
    .its-forgot--button-container {
        margin-right: 0;
        .its-button {
            padding: 0px 8px;
        }
    }
}

@media (max-height: 700px) {
    .its-forgot--logo-spacer {
        display: none;
    }

    .its-forgot--logo-container {
        max-height: 150px;
    }
}

@media (max-height: 490px) {
    .its-forgot--logo-container {
        max-height: 75px;
    }
}

@media (max-height: 400px) {
    .its-forgot--new-customer-container,
    .its-reset--logo {
        display: none;
    }
    .its-forgot--modal-container {
        margin: auto 10px;
    }
}
