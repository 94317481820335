#its-sidebar {
    &::-webkit-scrollbar-thumb {
        background: $basePrimaryColour85;
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
            background: $basePrimaryColour;
        }
    }
}
.autocomplete-no-shadow {
    md-autocomplete-wrap {
        box-shadow: none;
    }
}

.rotate-180 {
    transform: rotate(180deg);
}

.its-searchbar-no-margin {
    .its-searchbar--container {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
    }
}

.md-dialog-content-body {
    p {
        margin-top: 14px;
    }
}

.no-margin {
    margin: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.its-accordion .its-accordion-list-item .its-accordion-list-item--label.highlighted,
.its-accordion
    .its-accordion-list-item
    .its-accordion-list-item--label.highlighted
    .its-accordion-list-item--label--icon {
    color: $primaryAccessColour;
}

.its-contained-layout--container {
    .its-contained-layout--item {
        border-left: 3px solid $primaryAccessColour;
        .its-contained-layout--inner-container {
            border-left: 2px solid $primaryAccessColour;
        }
    }
}

@media screen and (max-width: 600px) {
    .its-contained-layout--container {
        .its-contained-layout--item {
            border-bottom: 2px solid $primaryAccessColour;
        }
    }
}

.its-datalist--header {
    height: auto;
}

#its-topbar {
    position: relative;
}

.its-toolbar {
    width: auto;
}

#its-sidebar {
    position: relative;
}

.its-datalist-no-add-margin {
    margin-bottom: 10px;
}

md-dialog-content {
    a {
        color: $basePrimaryColour;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.its-froala {
    strong {
        font-weight: bold;
    }
    em {
        font-style: italic !important;
    }
    ul {
        list-style: disc;
    }
    ol {
        list-style: decimal;
    }
}
ite-tag-select {
    #createTagBtn {
        margin-left: -36px !important;
        margin-top: 15px !important;
    }
}

md-progress-circular {
    &.its-white-progress-circular {
        path {
            stroke: $White;
        }
    }
}
