.its-permissions {
    margin-top: 48px;
}

.its-permissions--sidenav {
    width: 100%;
}

.its-permissions--content {
    margin-left: 5px; // so menu line doesn't get cut off on the left
}

.its-permissions--slug {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.its-empty-state--no-margin {
    .its-empty-state {
        margin-top: 0;
    }
}
.its-modal-content--searchable-padding {
    min-height: 650px;
}
.its-empty-state--no-icon {
    .its-empty-state {
        .its-empty-state--icon {
            display: none;
        }
    }
}
