.its-trace-datalist {
    .its-trace-datalist-cell {
        text-transform: capitalize;

        &.its-selectable {
            .its-datalist--cell-text span {
                cursor: pointer;
                border-bottom: 1px dashed #aaa;
            }
        }
    }

    .its-trace-datalist-row {
        max-height: none !important;
    }
}

.its-trace-date-filter {
    background-color: #00a3e0;
    padding: 2px 2px 2px 12px;
    margin: 2px;
    border-radius: 18px;
    display: inline-block;

    .md-select-value {
        padding: 0 8px 0 6px;
        border-bottom: none !important;
        span {
            @extend .its-text__strong;
            @extend .its-text__white;
        }

        span:not(.md-select-icon) {
            transform: none !important;
        }
    }

    .md-select-icon {
        margin-top: 4px;
        width: 18px;
        color: white;
    }
}

.ita-toolbar-input {
    margin-top: 5px;
    margin-bottom: 5px;
}

.its-pill-container {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    float: left;
    border: 1px solid #ddd;
    border-radius: 20px;
    margin-right: 5px;

    .its-pill-end {
        border-right: 1px solid #ddd;
    }

    .its-pill-text {
        padding: 8px 10px;
        text-transform: capitalize;

        &.its-selectable {
            span {
                cursor: pointer;
                border-bottom: 1px dashed #aaa;
            }
        }
    }
}
