.its-settings-content {
    background: white;

    h2 {
        margin-bottom: 20px;
    }

    md-content {
        background: white;
    }

    .its-settings-content--section {
        padding-top: 20px;
        padding-bottom: 20px;

        h3 {
            margin-bottom: 20px;
        }

        h4 {
            margin-bottom: 10px;
        }

        .its-settings-content--section--content {
            margin-left: 20px;
        }
    }
}

.its-settings-subscription-upgrade-contact-us {
    margin-top: 1px;
    margin-left: 10px;
    font-weight: 500;
}

.its-settings-subscription-upgrade-action-icon {
    margin-left: 20px;
}

.its-settings-content--subscription-card-container {
}

.its-settings-content--subscription-card {
    margin: 20px;
    border: 1px solid #ddd;

    .its-settings-content--subscription-card--header {
        padding: 10px;
        background: #ddd;
        max-height: 50px;
        min-height: 50px;

        .its-settings-content--subscription-card--header--text {
            text-align: center;
            font-size: 18px;
            color: black;
        }
    }

    .its-settings-content--subscription-card--info {
        text-align: center;

        .its-settings-content--subscription-card--info--description {
            margin: 40px;
        }

        .its-settings-content--subscription-card--info--products {
            margin-top: 20px;
            margin-bottom: 40px;

            .its-settings-content--subscription-card--info--products--item {
                margin-bottom: 10px;
            }
        }

        .its-settings-content--subscription-card--info--pricing {
            margin-top: 20px;
            height: 100px;

            .its-settings-content--subscription-card--info--pricing--unit-price {
                font-size: 48px;
                height: 57px;
                line-height: 57px;

                .its-settings-content--subscription-card--info--pricing--unit-price--currency {
                    vertical-align: super;
                    font-size: 22px;
                }
            }

            .its-settings-content--subscription-card--info--pricing--billing-strategy {
                margin-top: 10px;
                opacity: 0.7;
            }
        }

        .its-settings-content--subscription-card--info--metrics {
            margin: 20px;
            line-height: 22px;
            opacity: 0.7;
        }

        .its-settings-content--subscription-card--info--text {
            text-align: center;
            font-size: 12px;
            color: black;
        }

        .its-settings-content--subscription-card--info--features {
            text-align: left;
            margin-top: -25px;
            margin-left: 30px;
            margin-right: 30px;
        }
    }

    &.its-settings-content--subscription-card-selected {
        .its-settings-content--subscription-card--header {
            background: #00a3e0;

            .its-settings-content--subscription-card--header--text {
                font-weight: 500;
                color: white;
            }
        }
    }

    .its-settings-content--subscription-card--footer {
        border-top: 1px solid #ddd;
        max-height: 50px;
        min-height: 50px;
        height: 50px;
    }
}

.its-settings---payment-error {
    color: red;
    margin-left: 20px;
    font-weight: 500;

    md-icon {
        font-size: 19px;
        color: red;
    }
}

.its-settings---cc-on-file {
    margin-bottom: 20px;

    .its-settings---cc-on-file--details {
        margin-left: 20px;

        .its-settings---cc-on-file--type {
            text-transform: capitalize;
        }
    }
}

.its-settings---cc-form {
    margin-top: 30px;
    max-width: 600px;

    #payment-form {
        margin-left: 20px;
    }

    #card-errors {
        color: red;
        margin-top: 3px;
        margin-left: 5px;
    }

    .StripeElement {
        box-sizing: border-box;

        height: 40px;
        padding: 10px 12px;

        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: white;

        box-shadow: 0 1px 3px 0 #e6ebf1;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
    }

    .StripeElement--focus {
        box-shadow: 0 1px 3px 0 #cfd7df;
    }

    .StripeElement--invalid {
        border-color: #fa755a;
    }

    .StripeElement--webkit-autofill {
        background-color: #fefde5 !important;
    }
}
